<template>
    <div style="background-color: #262626">
      <v-row justify="center">
        <v-col cols="12">
          <v-row justify="center" class="pl-lg-3">
            <v-col cols="12" lg="2" class="px-0">
                <v-avatar size="25%">
                    <v-img  contain src="../assets/images/lg.png"><span></span></v-img>
                </v-avatar>
                <span style="color: white" class="logohead">&nbsp;&nbsp;&nbsp;Rapid Invoice</span>
                <v-row justify="center">
          <v-col cols="12" class="app">
            <p style="color: white; text-align: justify;">
                An app designed to simplify complexities and provide the solution you truly deserve.
            </p>
          </v-col>
        </v-row>
            </v-col>
            <v-col cols="12" lg="3" md="3" sm="3" class="pl-16-lg pt-10">
              <v-col class="sub" cols="12 sub">
                <v-row justify="end">
                  <v-col cols="12" lg="9" class="text-left">
                    <v-btn style="color: white" variant="text">ABOUT</v-btn>
                  </v-col>
                  <v-col class=" text-left sub" cols="12" lg="9"
                    ><v-btn variant="text" @click="contact()">Terms Of Service</v-btn></v-col
                  >
                  <v-col class=" text-left sub" cols="12" lg="9"
                    ><v-btn variant="text" @click="privacy()"
                      >Privacy Policy</v-btn
                    ></v-col
                  >
                </v-row>
              </v-col>
            </v-col>
            <v-col cols="12" lg="3" md="3" sm="3" class="pl-16-lg pt-10">
              <v-col cols="12">
                <v-row justify="end">
                  <v-col class="sub"  cols="12" lg="9"
                ><a
                  href="https://www.linkedin.com/company/leopardtechlabs/"
                  ><v-btn  variant="text" class="custom-button">LinkedIn</v-btn></a
                ></v-col
              >
              <v-col  cols="12" lg="9"
                ><a
                  href="https://www.facebook.com/leopardtechlabs"
                  ><v-btn variant="text" class="custom-button">Facebook</v-btn></a
                ></v-col
              >
              <v-col  cols="12" lg="9"
                ><a
                  href="https://www.instagram.com/leopardtechlabs/"
                  ><v-btn variant="text" class="custom-button"
                    >Instagram</v-btn
                  ></a
                ></v-col
              >
                </v-row>
              </v-col>
            </v-col>
            <v-col cols="12" lg="3" md="3" sm="3" class="pl-16-lg pt-10">
              <v-col class="sub" cols="12">
                <v-row justify="end">
                  <v-col cols="12" lg="9" class="text-left sub">
                    <span>Info@leopardtechlabs.com</span>
                  </v-col>
                  <v-col cols="12" lg="9" class="text-left sub">
                    <span>leopardtechlabs@gmail.com</span>
                  </v-col>
                  <v-col class=" text-left sub" cols="12" lg="9"
                    ><span>+91 62380 89035</span></v-col
                  >
                  <v-col class=" text-left sub" cols="12" lg="9"
                    ><span>+91 85479 33472</span></v-col
                  >
                </v-row>
              </v-col>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row justify="center" class="ftr" style="background-color: #000000">
        <v-col class="text-center" cols="11" lg="7" align-self="center">
          <span style="color: white" class="ftr1"
            >©  Rapid Invoice  2023 | Designed & Developed by <span style="color: rgb(255, 0, 0)" class="ftr2">Leopard Tech Labs Pvt Ltd</span></span
          >
        </v-col>
      </v-row>
    </div>
    <div></div>
  </template>
    <style scoped>
  .sub {
    color: #ffffff;
    font-size: 18px;
    font-family: lato;
    font-weight: 500;

  }
  .ftr1{
    font-family: dmsans;
    font-size: 17px;
    font-weight: 400;
  }
  .ftr2{
    font-family: dmsans;
    font-size: 17px;
    font-weight: 500;
  }
  .logohead {
    color: #cfc7c7;
    font-size: 25px;
    font-weight: 900;
    font-family: logo;
  }
  .ftr {
    font-size: 15px;
    font-weight: 400;
    font-family: opensans;
  }
  .v-row {
    margin: 0px;
  }
  .custom-link {
    text-decoration: none;
    color: #cfc7c7;
  }
  
  .custom-link:hover {
    text-decoration: none;
  }
  
  .custom-button {
    background: transparent;
    color: #ffffff;
    font-family: lato;
  }
  .app{
    font-size: 17px;
    font-weight: 400;
    font-family: dmsans;

  }
  </style>
  <script>
  export default {
    data() {
      return {};
    },
    methods: {
      home() {
        this.$router.push("/");
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      },
      gallery() {
        this.$router.push("/gallery");
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      },
      product() {
        this.$router.push("/procisstem");
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      },
      contact() {
        this.$router.push("/termsofservice");
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      },
      privacy() {
        this.$router.push("/privacypolicy");
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      },
    },
    
  };
  </script>