<template>
    <div>
        <v-row justify="center" class="pa-4 px-16">
            <v-col lg="12">
                <v-row>
                    <v-col lg="6">
                        <v-row class="fill-height">
                            <v-col lg="1">
                                <v-img src="../assets/images/Logo.png" width="100%" height="100%"></v-img>
                            </v-col>
                            <v-col lg="11" align-self="center">
                                <span>Rapid Invoice </span>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col lg="6">
                        <v-row>
                            <v-col lg="3">
                                <v-btn variant="plain">Features </v-btn>
                            </v-col>
                            <v-col lg="3">
                                <v-btn variant="plain">About </v-btn>
                            </v-col>
                            <v-col lg="3">
                                <v-btn variant="plain" @click="scrollToSupport">Support </v-btn>
                            </v-col>
                            <v-col lg="3">
                                <v-btn color="#EA3030">Download </v-btn>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>    
    </div>
</template>
<script>
export default {
  methods: {
    scrollToSupport() {
      // Assuming you have a route named 'support'
      this.$router.push({ name: 'supportpage' });

      // Scroll to the corresponding element with an ID 'support-section'
      const supportSection = document.getElementById('support-section');
      if (supportSection) {
        window.scrollTo({
          top: supportSection.offsetTop,
          behavior: 'smooth',
        });
      }
    },
  },
};
</script>